<template lang="html">
  <div class="">

    <div class="main">
      <div class="subdiv">
        <div :style="{display:'flex'}">
          <div class="">
            <h1 :style="{fontSize:'26px',fontWeight:'bold',textAlign:'left'}">
                 {{this.name}}
            </h1>
            <h2 class="h2_dator" >
              {{ this.short_description }}<br> Besök {{this.name}}  <a :href='this.website'   >
                <span :style="{height:'25px',width:'25px', textDecoration:'underline'}" >
                  här
                </span>
               </a>
            </h2>
          </div>
          <img :src="`${publicPath}`+ this.logo" alt="" class="logo_image">
        </div>

        <div class="article_webb" >
          <div class="">
            <div :style="{width:'80%'}" v-for="(article_part,idx) in article_text" :key="idx">
              <h2 :style="{fontSize:'17px',fontWeight:'bold',marginTop:'2%',textAlign:'left'}">
                {{article_part.headline}}
              </h2>
              <h3 :style="{fontSize:'15px',textAlign:'left',marginTop:'0.2%'}">
                {{article_part.text}}
              </h3>
            </div>
          </div>
          <div :style="{marginTop:'4%'}">
            <img :src="`${publicPath}`+ this.image" alt="" :style="{height:'295px', marginTop:'1%',width:'290px'}">
          </div>
        </div>
        <div class="article_mob" >
          <div class="">
            <h2 class="h2_mob" >
              {{ this.short_description }}<br> Besök Spågan  <a :href='this.website'   >
                <span :style="{height:'25px',width:'25px', textDecoration:'underline'}" >
                  här
                </span>
               </a>
            </h2>
            <div :style="{width:'80%'}" v-for="(article_part,idx) in article_text" :key="idx">
              <h2 :style="{fontSize:'17px',fontWeight:'bold',marginTop:'2%',textAlign:'left'}">
                {{article_part.headline}}
              </h2>
              <h3 :style="{fontSize:'15px',textAlign:'left',marginTop:'0.2%'}">
                {{article_part.text}}
              </h3>
            </div>
          </div>
          <div :style="{textAlign:'left'}">
            <img :src="`${publicPath}`+ this.image" alt="" :style="{height:'195px', marginTop:'4%',width:'210px'}">
          </div>

        </div>

      </div>
    </div>


  <bottombar/>

  </div>
</template>

<script>

import bottombar from '../components/bottombar.vue';


export default {
  data: function () {
    return {
      name:"",
      short_description:"",
      article_list:[],
      article_text:[],
      website:"",
      publicPath: process.env.BASE_URL +'',
      image:"",
      logo:"",
      id:0
    }
  },
  components:{
    bottombar
  },
  mounted(){
    this.id=this.$route.query.id

    window.scrollTo(0,0)
    this.article_list = [
      {
        "id": 0,
        "name": "Spågan ",
        "short_description": "Välkommen till vår artikelserie “Möt en reparatör” där vi intervjuar reparatörer på Enjord.com. Först ut är möbelkonservatorn Jon Bjellerup på Spågan Möbelkonservering i Stockholm.",
        "meta_title": "Spågan - Möbelkonservering" ,
        "meta_descripton":'Intervju med Spågan om möbelkonservering',
        "image": "spagan_art.png",
        "logo":"spagan.png",
        "website":"https://www.enjord.com/company_page?id=3003",
        "article_text":[
          {"headline":"Välkommen Jon, berätta gärna lite om dig själv och Spågan", "text": "Tack. Jag heter Jon Bjellerup och är ursprungligen från Skåne där jag även påbörjade min utbildning och karriär som möbelkonservator och möbelsnickare. Mitt intresse för möbler kommer dels från min mamma och i hennes tur från min gammelmorfar som arbetade i yrket för NK. Då ingen möbel är den andra lik så passade detta arbete mig perfekt. Karriären startade när jag tog över en verksamhet från min mentor Berny Perzon i Ystad och fortsatte sedan med en utbildning på Carl Malmsten och ett halvårs praktik i Nederländerna. Spågan, i sin nuvarande form, startade 2017 och har varit i Stockholm i fyra års tid nu. Det vi gör är unikt i Sverige och vi har en spetskompetens inom möbelvård. Detta är anledningen till att flera museer och antikhandlare anlitar oss för många av deras restaureringar. Även privatpersoner köper våra tjänster tack vare vårt fokus på att reparera skador, förnya ytor eller återställa möblers ursprungliga skönhet. Det är vår prioritet att bevara möblernas autenticitet och historiska karaktär. Vi är idag 4 anställda med Jon, Robin och Viktor som alla är möbelsnickare och konservatorer samt Irina från Ukraina som är korgmakare. Just korgmakeri är ett hantverk som i princip inte finns i Sverige idag, så det är extra roligt att få inkludera Ukrainsk spetskompetens inom detta område i vårt företag"},
          {"headline":"Hur skiljer sig möbelrestaurering mellan olika länder?",
           "text": "Den största skillnaden mellan möbelrestaurering i Norden och kontinenten är att vi i Sverige är mer teoretiska och lever efter devisen ”minsta möjliga åtgärd”. Vi vill att möbeln ska vara så autentisk som möjligt även efter restaureringen. Däremot, på kontinenten, så kan en restaurering leda till att möbeln kan se nästan ny ut vilket riskerar att eliminera den autentiska känslan. Vi på Spågan har utvecklat en kombination av detta, det vill säga om man köper en möbel från 60-talet så ska det se ut som att det kommer från tiden men vi ser även till att den håller i 20, 30, 40 år till. Vi tänker på en möbelrestaurering som en investering där inte bara du, men även dina barn och barnbarn ska få njuta av möbeln. Din farmors skrivbord ska kännas som farmors skrivbord även efter att det har reparerats."},
          {"headline":"Vilka är de vanligaste skadorna ni får in?", "text": "Vi är experter på all typ av trärestaurering och det vanligaste vi får in är skador i ytbehandlingar och möbler som behöver limmas om. Det är även vanligt med flätningar av olika slag. Vår målsättning är att våra kunder ska få fortsätta njuta och använda sina möbler under många år framöver och vi är här för att hjälpa till med alla restaureringsbehov."},
          {"headline":"Vad säger era kunder om er?",
           "text": "De känner att det är väldigt trevligt att komma till oss. Vi ser alltid till att prata med kunder om vad som passar deras hem och vad som passar möbeln. Vi är noga med att göra reparationer anpassade efter den specifika möbeln. Varje restaurering är unik. Vi är väldigt noga med att diskutera med kunderna om hur möblerna ska användas och tas om hand. Vi svarar också gärna på frågor om hur man ska tänka gällande en reparation som man vill göra själv och tips på vad man ska, eller inte ska göra. Allt för att ta hand om möbeln på absolut bästa sätt."},
          {"headline":"Vad vill ni att kunderna ska veta?",
           "text": "Även om priset på en reparation kan vara högt så måste man tänka att vi restaurerar möbler som är tänkta att hålla i årtionden, inte bara i år. Tänk på möbelrestaurering som en investering och att möblerna ska finnas och fungera i flera generationer. Med det i åtanke så blir priset mycket lägre jämfört med billiga slit-och-släng produkter.   •	Håll dig borta från trender som att måla möblerna vita och låt oss föreslå vad bästa lösningen är för att restaurera just din möbel. •	Sist men inte minst är det viktigt att man ska intressera sig för sina möbler. De är roligare att äga och ha omkring sig då. Det är roligare om du vet vad du har och om du vet historien bakom exempelvis skrivbordet. Vi hoppas att kunderna ska intressera sig för sin inredning och vi berättar med glädje så mycket som möjligt om deras möbel och bjuder alltid på vår kunskap. Ring och fråga om du undrar något, det är både gratis och intressant."},
          {"headline":"Vad är något ni har uppmärksammat hos nya kunder?", "text": "Att nästa generation av kunder tänker annorlunda. Yngre kunder hör av sig och frågar om reparationerna och har generellt sett ett större miljömedvetande. De är fokuserade på bevarande och kvalitet snarare än slit- och slängprodukter. Vi ser att yngre köpare ser möbler mer som en investering, vilket är väldigt kul och bådar gott för framtiden."},
          {"headline":"Hur har samarbetet med Enjord gått? ", "text": "Bra, de är mycket lyhörda, nyfikna och tar detta på allvar. Det vill skapa en tjänst för reparatörer och har ett långsiktigt tänk i sin produkt. Det märks att de vill göra en insats för miljön!"}
        ]
      },
      {
        "id": 1,
        "name": "Karin Falk Dreier textilkonservering ",
        "short_description": "Välkommen till vår artikelserie “Möt en reparatör” där vi intervjuar reparatörer på Enjord.com. Denna artikel är med textilkonservatorn Karin Falk Dreier baserad i Huskvarna.",
        "meta_title": "Karin Falk Dreier textilkonservering" ,
        "meta_descripton":'Intervju med Karin Falk Dreier om textilkonservering',
        "image": "kf_insta.png",
        "logo":"karinfalk.png",
        "website":"https://www.enjord.com/company_page?id=3917",
        "article_text":[
          {"headline":"Välkommen Karin till Enjord - berätta gärna lite om dig själv", "text": "Tack. Mitt namn är Karin Falk Dreier och jag driver min textilkonserveringsfirma i Huskvarna, där jag renoverar textilier, både konsttextil och brukstextil, vanliga klädesplagg och inredningstextilier. Jag håller även kurser inom textilvård. Jag är utbildad textilkonservator, men började min textila bana som textilhantverkare efter att ha gått väv- och textilutbildningar på folkhögskola och högskola. Genom dessa utbildningar kom jag i kontakt med museisamlingar och textilier som var helt nya för mig. Mönster och hantverkstekniker som var fantastiska. Viljan att ta hand om det som redan finns i form av föremål, material, tekniker och mönsterskatter gjorde att jag valde bort mina tänkta biologistudier och sökte mig till konservatorsutbildningen vid Göteborgs universitet med textil som specialité. I konservatorsutbildningen ingår kunskapen om vad material består av och hur de bryts ner. Vet man det så kan man bromsa sönderfallet genom att hantera föremålen på rätt sätt. Denna kunskap är också värdefull för att kunna skilja på bättre och sämre kvaliteter i nutid. Det är fascinerande att saker som är 200 år gammalt fortfarande kan vara fina med bra kvalitet medan en del av de nutida textilierna, kanske bara 20 år gamla, redan är på väg att vissna bort."},
          {"headline":"Var kommer ditt miljöintresse ifrån?",
           "text": "Miljöintresset och kärleken för naturen har jag haft sen tidig ålder och det var troligen anledningen till att jag tog mig in i världen av konservering. Jag drivs av att ta hand om det som redan är och finns och eftersom den textila värden har många baksidor, både vad gäller produktionen av textilier och att konsumtionen är så bisarr, så finns det mycket att arbeta med inom miljö och hållbarhet här. Det finns ett engagemang i samhället för att ta hand om sina kläder, men det behöver växa för att vi inte ska tära mer på naturen genom t.ex. storskalig, vattenkrävande bomullsproduktion, skadliga kemikalier mm. Förutom att reparera och rengöra textilier så håller jag kurser och workshops inom textilvård och lagning. I stort och smått. Intresserade är välkomna att höra av sig till mig. Jag är som många andra väldigt påverkad av omvärlden. Det är en psykiskt påfrestande tid med allt som pågår, men jag håller mig flytande genom mitt engagemang i Naturskyddsföreningen och nyligen deltog jag i en demonstration med rebellmammorna i Växjö. Det var fint."},
           {"headline":"Berätta lite om ert företag - Hur började allt?",
            "text": "Jag grundade mitt första företag år 2003 och har sedan dess sysslat med det på och av. De senaste 10 åren har jag drivit företag utan uppehåll. Det är ett väldigt kul, utmanande och lärorikt yrke."},
          {"headline":"Vilka är de vanligaste felen ni får in?",
           "text": "De vanligaste felen vi får in är slitage och fläckar på soffor och inredningstextilier. Vi får även in många nötta mattfransar samt kläder med slitna fickor och nötta kragar. En kul utveckling är att vi får in mycket ärvda saker som kunderna vill fortsätta använda. Det kan vara en ärvd brudslöja, en textil tavla mm. Föremål som av tidens tand är lite mera ömtåliga och behöver tas hand om. Det går oftast att rengöra och laga så det är väldigt välkommet när folk kommer in med det."},
          {"headline":"Vad säger era kunder om er?",
           "text": "Jag upplever att folk blir imponerade av att man kan laga saker och att det blir fint och bra. Deras nyfikenhet och intresse är väldigt kul att se och det märks att de anser det vara häftigt och imponerande att kunskapen att laga finns kvar vilket är roligt att se."},
          {"headline":"Vilka är de vanligaste misstagen folk gör när de ska ta hand om sina textilier?",
           "text": "När det kommer till kläder så är det största problemet att man tvättar dem onödigt ofta. Detta leder till att plaggen slits i förtid. Min rekommendation är att många gånger nöja sig med att ta bort fläckar. Tvätta de plagg som är nära kroppen men för plagg såsom tröjor och jackor kan det räcka med att vädra dem. Att inte värdesätta textilierna för vad de är. Textilier är generellt alldeles för billigt idag och värdesätts därefter. För att det ska bli en förändring så tror jag att kunskapen om textil och den textila produktionen behöver bli mera allmängiltig i samhället.  Att respektera värdet av textilierna är viktigt. Att inte slänga sina kläder till återvinning för fort. Sy om, ge bort och byt när du själv har tröttnat på ett plagg."},
          {"headline":"Vad vill ni att era nuvarande/framtida kunder ska veta om er?", "text": "Att jag strävar efter att i mina lagningar och reparationer använda återbrukat material och att jag vill göra så lite avtryck på miljön som möjligt. Detta går genom hela min affärside och tankesätt."},
          {"headline":"Vad har ni har uppmärksammat hos nya kunder?",
           "text": "Att det finns ett allmänt större miljöintresse från privatpersoner. Jag tror att när man verkligen börjar titta i sina gömmor med viljan att ta hand om så kan även intresset för hantverk och historia växa."},
          {"headline":"Hur har samarbetet med Enjord gått? ", "text": "Det har varit väldigt positivt. Konceptet känns som en bra idé och är ett område där det behövs lösningar som underlättar för folk att reparera. Det tar tid för oss människor att förändra våra beteenden, så allt som förenklar för dem är positivt."}

        ]
      }

    ]
    this.name = this.article_list[this.id]["name"]
    this.short_description = this.article_list[this.id]["short_description"]
    this.website = this.article_list[this.id]["website"]
    this.image = this.article_list[this.id]["image"]
    this.logo = this.article_list[this.id]["logo"]
    this.article_text= this.article_list[this.id]["article_text"]

  }

}
</script>

<style lang="css" scoped>
.main{
  width:100%;
  margin-top: 3%;
  padding-bottom: 80px;
  background-color: white;
}

@media (max-width:450px ){
  .article_webb{
    display: none
  }
  .subdiv{
    width:94%;
    margin-top: 2%;
    margin-left: 6%;
    padding-bottom: 80px
  }

  .h2_mob{
    margin-top: 3%;
    width: 90%;
    font-size: 19px;
    text-align: left;
    padding-bottom: 10px;
  }

  .h2_dator{
    display: none
  }

  .logo_image{
    height:40px;
    margin-left:14%;
    margin-top: 1%;
    width:50px
  }


}

@media (min-width:450px ){
  .subdiv{
    width:80%;
    margin-top: 2%;
    margin-left: 6%;
    padding-bottom: 80px
  }

  .article_webb{
    margin-top: 4%;
    display: flex;
  }
  .article_mob{
      display: none
  }

  .h2_dator{
    font-size: 19px;
    text-align: left;
  }

  .h2_mob{
    display: none
  }

  .logo_image{
    height:80px;
    margin-left:29%;
    margin-top: 1%;
    width:120px
  }


}

</style>
